<template>
  <v-container>
    <!-- <v-divider></v-divider> -->
    <v-form ref="form">
      <v-layout row wrap justify-space-between class="mx-auto mb-4">
        <h2 class="title text-center grey--text">Update owner details</h2>
        <v-flex xs12 sm12 md12 lg12 class="text-right">
          <v-btn
            small
            dark
            class="mr-4"
            :color="$store.state.secondaryColor"
            @click="backToProfile()"
          >
            <v-icon small left>fas fa-arrow-left</v-icon>
            <span class="caption text-capitalize">{{ $t("back") }}</span>
          </v-btn>

          <v-btn
            small
            dark
            class="mr-4"
            :color="$store.state.primaryColor"
            :loading="loading"
            @click="updateOwner"
          >
            <v-icon small left>save</v-icon>
            <span class="caption text-capitalize">{{ $t("save update") }}</span>
          </v-btn>
        </v-flex>
      </v-layout>

      <v-snackbar top center v-model="snackbar">
        {{ $t("owner details successfully updated") }}!
        <template v-slot:action="{ attrs }">
          <v-btn
            :color="$store.state.secondaryColor"
            text
            v-bind="attrs"
            @click="snackbar = false"
            :timeout="timeout"
          >
            {{ $t("close") }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-divider></v-divider>
      <v-layout row wrap>
        <v-flex xs12 sm3 md3 lg3 class="px-1 text-center">
          <v-card class="mx-auto mb-2" tile max-width="344">
            <v-img :src="register.avatar" height="200px"></v-img>
          </v-card>

          <v-file-input
            placeholder="Upload picture"
            dense
            solo
            flat
            outlined
            prepend-icon="mdi-camera"
            @change="previewPicture"
            small-chips
          ></v-file-input>
        </v-flex>
        <v-flex xs12 sm9 md9 lg9 class="px-4">
          <v-layout row wrap class="mt-3 caption elevation-1">
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("firstname") }}* :
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.names.first"
                :rules="rules.firstName"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("middlename") }}:
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.names.middle"
                :rules="rules.middleName"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("lastname") }}:
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.names.last"
                :rules="rules.lastName"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("gender") }}:
              </span>
              <v-radio-group
                v-model="register.gender"
                :rules="rules.gender"
                row
              >
                <v-radio value="M">
                  <template v-slot:label>
                    <div>M</div>
                  </template>
                </v-radio>

                <v-radio value="F">
                  <template v-slot:label>
                    <div>F</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>

          <v-layout row wrap class="mt-3 caption elevation-1">
            <v-flex xs12 sm4 md4 lg4 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("email") }}:
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.email"
                :rules="rules.email"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm4 md4 lg4 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("telephone") }}:
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.phone"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm4 md4 lg4 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("PO BOX") }}:
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.address.pobox"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout row wrap class="mt-3 caption elevation-1">
            <v-flex xs12 sm4 md4 lg4 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("country") }}:
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.address.country"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm4 md4 lg4 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("county") }}:
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.address.county"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm4 md4 lg4 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("city") }}:
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.address.city"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout row wrap class="mt-3 caption elevation-1">
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("street") }}:
              </span>
              <span> : </span>
              <v-text-field
                outlined
                dense
                v-model="register.address.street"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("estate") }}:
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.address.estate"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize">
                {{ $t("building") }}:
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.address.building"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("house-suite-number") }}:
              </span>
              <v-text-field
                dense
                outlined
                v-model="register.address.no"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import fb from "firebase/app";

// import    { format }  from   'date-fns'

export default {
  data: () => ({
    file: "",
    loading: false,
    snackbar: false,
    timeout: 2000,
    register: null,
    businesses: [],
    // businessNames:[],
  }),

  created() {
    this.getOwnerDetails();
  },

  computed: {
    imageName() {
      return this.register.businessId + "/" + this.register.staffId;
    },
    rules() {
      return {
        firstName: [
          (v) => !!v || this.$t("first name is required"),
          (v) =>
            (v && v.length <= 10) ||
            this.$t("name must be less than 15 characters"),
        ],
        middleName: [
          (v) => !!v || this.$t("middle name is required"),
          (v) =>
            (v && v.length <= 10) ||
            this.$t("name must be less than 15 characters"),
        ],
        lastName: [
          (v) => !!v || this.$t("last name is required"),
          (v) =>
            (v && v.length <= 10) ||
            this.$t("name must be less than 15 characters"),
        ],
        email: [
          (v) => !!v || this.$t("e-mail is required"),
          (v) => /.+@.+\..+/.test(v) || this.$t("please enter a valid email"),
        ],
        gender: [(v) => (v || "").length > 0 || this.$t("please select one")],
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
  },

  methods: {
    getOwnerDetails() {
      db.collection("owners")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.register = doc.data();
        })
        .catch((error) => {
          console.log("Error getting  document:", error);
        });
    },

    updateOwner() {
      if (this.file != "") {
        this.loading = true;
        let uploadTask = fb
          .storage()
          .ref("users/" + this.imageName)
          .put(this.file);

        uploadTask.on(
          "state_changed",
          () => {},
          () => {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
              this.register.avatar = downloadLink;

              db.collection("owners")
                .doc(this.$route.params.id)
                .update(this.register)
                .then(() => {
                  this.loading = false;
                  this.file = "";
                  this.snackbar = true;
                  setTimeout(function () {
                    this.backToProfile();
                  }, 2000);
                })
                .catch((error) => {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      } else {
        this.loading = true;
        db.collection("owners")
          .doc(this.$route.params.id)
          .update(this.register)
          .then(() => {
            this.loading = false;
            this.file = "";
            this.snackbar = true;
            setTimeout(function () {
              this.backToProfile();
            }, 2000);
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
          });
      }
    },

    previewPicture(event) {
      this.file = event;
      this.register.avatar = URL.createObjectURL(this.file);
    },

    backToProfile() {
      this.$router.push({
        name: "profileOwner",
        params: { id: this.$route.params.id },
      });
    },
  },
};
</script>

<style></style>
